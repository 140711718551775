import React from 'react';
import './App.scss';
import {BlindsGrid} from "./BlindsGrid";
import {Timer} from "./Timer";
import {Settings} from "./Settings";
import {About} from "./About";
import {Combinations} from "./Combinations";


const App = () => <div className='app-root d-flex align-items-center justify-content-around'>
  <BlindsGrid/>
  <About/>

  <Combinations/>

  <div className='d-flex flex-column'>
    <Timer/>
  </div>

</div>

export default App;
